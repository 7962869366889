import { createDetailsWidget } from "@livechat/agent-app-sdk";

console.log('Hello');

createDetailsWidget().then(widget => {
    console.log('Created details widget');
    widget.on("customer_profile", profile => {
        // let authResponse = hughsFunction(profile.id);
        console.log('Got customer profile');
        let authResponse = true;
        console.log(profile);
        console.log(profile.customVariables);
        var xhr = new XMLHttpRequest();
        xhr.open("POST", "https://sv74mk82x3.execute-api.eu-west-1.amazonaws.com/Prod/chat/verify/sapcu", true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(JSON.stringify({
            session: profile.customVariables["secret"]
        }));
        xhr.onload = function() {
            var data = JSON.parse(this.responseText);
            console.log(data);
        };
        widget
            .modifySection({
                title: "Authentication",
                components: [
                    {
                        type: "label_value",
                        data: {
                            label: "Authenticated: ",
                            value: (authResponse ? "✅" : "❌"),
                            inline: true
                        }
                    },
                    {
                        type: "label_value",
                        data: {
                            label: "Profile ID: ",
                            value: profile.id,
                            inline: true
                        }
                    },
                    {
                        type: "label_value",
                        data: {
                            label: "Chat ID: ",
                            value: profile.chat.id,
                            inline: true
                        }
                    }
                ]
            })
            .then(() => {
                // the widget should be modified now
                console.log('The widget should be modified now');
            });
    });

});
